/* eslint-disable react/prop-types */
import { Modal, Flex } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { BI } from "@ckb-lumos/bi";
import store from "../../../store/index.js";
import { saveLoading } from "../../../store/reducer.js";
import { formatUnit } from "@ckb-lumos/bi";
import { buy } from "../../../utils/txHelper.js";
import { sendTx } from "../../../api/dexRpc.js";
import ImageComponent from "../../image.jsx";
import { shortAddress} from "../../../utils/global.js";
import CkbImg from "../../../assets/ckb.png";
import {OrderArgs as OrderArqs} from "@nervina-labs/ckb-dex";

const Box = styled.div`

    .item{
        margin: 10px 0;
        gap:20px;
    }
    .num{
        font-size: 18px;
        font-weight: bold;
    }
    .symbol{
        opacity: 0.6;
        font-size: 12px;
    }
    .total{
        margin-left: 10px;
    }
`

const UlBox = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`

const PriceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    img{
        width: 20px;
        height: 20px;
    }
`

// eslint-disable-next-line react/prop-types
export default function BuyModal({ handleClose, show, selectItem, handleResult }) {
    // const connectData = useSelector(store => store.connectData);
    const account = useSelector(store => store.account);


    const [price, setPrice] = useState(0)

    let Sum = BI.from(0)

    useEffect(() => {
        selectItem.map((item) => {
            Sum = Sum.add(BI.from(item.price))
        })

        Sum = Sum.add(Sum.mul(BI.from(2)).div(BI.from(100)));
        let sumFormat = formatUnit(Sum, "ckb")
        setPrice(sumFormat)

    }, [selectItem]);


    const handleConfirm = async () => {
        store.dispatch(saveLoading(true));
        //
        try {
            const buyList = []
            for (let i = 0; i < selectItem.length; i++) {
                const item = selectItem[i];
                buyList.push({
                    args: item.dobsId,
                    assetType: item.assetType
                });
            }

            let txSkeletonObj = await buy(buyList, account);

            let rawTx = await window.rei?.ckb.request({
                method: "ckb_signRawTransaction",
                data: {
                    txSkeleton: txSkeletonObj
                }
            });

            let sendRs = await sendTx(rawTx);
            let txHash = sendRs.txHash;
            handleResult('success', "Success", txHash);

        } catch (e) {
            console.error("submitBuy", e)
            handleResult('error', "Failed", e.message)
        } finally {
            handleClose()
            store.dispatch(saveLoading(false));
        }


    }


    return <div>

        <Modal
            title="Buy"
            centered
            zIndex={98}
            open={show}
            onOk={() => handleConfirm()}
            onCancel={() => handleClose()}
        >
            <Box>
                <UlBox>
                {
                    selectItem.map((item, index) => (
                        <li key={index} className="flex justify-between w-full border-b pb-2 ">
                            <div className="flex gap-2 items-center">
                                <ImageComponent key={index} tokenKey={item.dobsId} size="50px" isDid={item.assetType === 1}/>
                                <div>
                                    <div className="text-xs text-slate-400">DOB ID</div>
                                    <div>{shortAddress(item.dobsId, 5)}</div>
                                </div>
                            </div>
                            <div className="flex justify-end flex-col items-end text-xs">
                                <PriceBox><img src={CkbImg} alt=""/>{formatUnit(item.price, "ckb")}
                                    <span>CKB</span></PriceBox>
                            </div>
                        </li>
                    ))
                }
            </UlBox>
                <Flex align="center" justify="center" className="item bg-gray-50 border rounded py-2">
                    <div>Buy <span className="text-primary011 font-din text-xl">{selectItem.length}</span> NFT, <span
                        className="total">Total</span></div>
                    <div>
                        <span className="text-primary011 font-din text-xl">{price}</span> <span className="symbol">CKB</span>
                    </div>
                </Flex>

            </Box>

        </Modal>
    </div>
}

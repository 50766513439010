import DobsDexSDK from "dobs-dex-sdk";
import { getCells } from "dobs-dex-sdk/lib/utils";
import { config, helpers, RPC } from "@ckb-lumos/lumos";

const isMannet = import.meta.env.VITE_CHAIN === "mainnet";

export const feeWithDraw = async (addr) => {
  let sdk = new DobsDexSDK(isMannet);

  let lock = helpers.addressToScript(addr, {
    config: isMannet ? config.MAINNET : config.TESTNET,
  });

  let rawTx = await sdk.feeWithDraw(lock);

  const rpc = new RPC(sdk.rpcURL);
  const fetcher = async (outPoint) => {
    let rs = await rpc.getLiveCell(outPoint, true);
    let cell = {
      cellOutput: {
        capacity: rs.cell?.output.capacity,
        lock: rs.cell?.output.lock,
        type: rs.cell?.output.type,
      },
      data: rs.cell?.data,
      outPoint,
    };
    return cell;
  };
  let txSkeleton = await helpers.createTransactionSkeleton(rawTx, fetcher);

  let txSkeletonObj = helpers.transactionSkeletonToObject(txSkeleton);

  return txSkeletonObj;
};

export const cancel = async (args, addr) => {
  // multi cancel
  let sdk = new DobsDexSDK(isMannet);

  let lock = helpers.addressToScript(addr, {
    config: isMannet ? config.MAINNET : config.TESTNET,
  });

  let cancelItem = [];

  let cancelArgs = args;

  for (let i = 0; i < cancelArgs.length; i++) {
    const item = cancelArgs[i];

    let rs = await getCells(sdk.rpcURL, {
      script: {
        code_hash:
          item.assetType == 0
            ? sdk.SPORE_SCRIPT.CODE_HASH
            : sdk.DID_SCRIPT.CODE_HASH,
        hash_type:
          item.assetType == 0
            ? sdk.SPORE_SCRIPT.HASH_TYPE
            : sdk.DID_SCRIPT.HASH_TYPE,
        args: item.args,
      },
      script_type: "type",
      script_search_mode: "exact",
    });

    if (rs.result && rs.result.objects.length > 0) {
      cancelItem.push(...rs.result.objects);
    }
  }

  let rawTx = await sdk.createMultiCancelOrderTx(lock, cancelItem);

  // console.log(rawTx)
  const rpc = new RPC(sdk.rpcURL);
  const fetcher = async (outPoint) => {
    let rs = await rpc.getLiveCell(outPoint, true);
    let cell = {
      cellOutput: {
        capacity: rs.cell?.output.capacity,
        lock: rs.cell?.output.lock,
        type: rs.cell?.output.type,
      },
      data: rs.cell?.data,
      outPoint,
    };
    return cell;
  };
  let txSkeleton = await helpers.createTransactionSkeleton(rawTx, fetcher);

  let txSkeletonObj = helpers.transactionSkeletonToObject(txSkeleton);

  return txSkeletonObj;
};

export const make = async (assetList,feeRate) => {
  // multi make
  let sdk = new DobsDexSDK(isMannet);

  let makeList = [];
  for (let i = 0; i < assetList.length; i++) {
    const item = assetList[i];
    makeList.push({
      code_hash:
        item.assetType == 0
          ? sdk.SPORE_SCRIPT.CODE_HASH
          : sdk.DID_SCRIPT.CODE_HASH,
      args: item.args,
      hash_type:
        item.assetType == 0
          ? sdk.SPORE_SCRIPT.HASH_TYPE
          : sdk.DID_SCRIPT.HASH_TYPE,
      priceCKB: item.priceCKB,
    });
  }


  let rawTx = await sdk.createMultiMakeOrderTx(makeList,feeRate);

  // console.log(rawTx)
  const rpc = new RPC(sdk.rpcURL);
  const fetcher = async (outPoint) => {
    let rs = await rpc.getLiveCell(outPoint, true);
    let cell = {
      cellOutput: {
        capacity: rs.cell?.output.capacity,
        lock: rs.cell?.output.lock,
        type: rs.cell?.output.type,
      },
      data: rs.cell?.data,
      outPoint,
    };
    return cell;
  };
  let txSkeleton = await helpers.createTransactionSkeleton(rawTx, fetcher);

  let txSkeletonObj = helpers.transactionSkeletonToObject(txSkeleton);

  return txSkeletonObj;
};

export const buy = async (buyArgs, buyAddr) => {
  let sdk = new DobsDexSDK(isMannet);

  let buyerScript = helpers.addressToScript(buyAddr, {
    config: isMannet ? config.MAINNET : config.TESTNET,
  });

  let buyItem = [];

  for (let i = 0; i < buyArgs.length; i++) {
    const item = buyArgs[i];


    let rs = await getCells(sdk.rpcURL, {
      script: {
        code_hash:
          item.assetType == 0
            ? sdk.SPORE_SCRIPT.CODE_HASH
            : sdk.DID_SCRIPT.CODE_HASH,
        hash_type:
          item.assetType == 0
            ? sdk.SPORE_SCRIPT.HASH_TYPE
            : sdk.DID_SCRIPT.HASH_TYPE,
        args: item.args,
      },
      script_type: "type",
      script_search_mode: "exact",
    });


    if (rs.result && rs.result.objects.length > 0) {
      buyItem.push(...rs.result.objects);
    }
  }


  let rawTx = await sdk.createMultiTakeOrderTx(buyItem, buyerScript,2000);

  const rpc = new RPC(sdk.rpcURL);
  const fetcher = async (outPoint) => {
    let rs = await rpc.getLiveCell(outPoint, true);
    let cell = {
      cellOutput: {
        capacity: rs.cell?.output.capacity,
        lock: rs.cell?.output.lock,
        type: rs.cell?.output.type,
      },
      data: rs.cell?.data,
      outPoint,
    };
    return cell;
  };
  let txSkeleton = await helpers.createTransactionSkeleton(rawTx, fetcher);

  let txSkeletonObj = helpers.transactionSkeletonToObject(txSkeleton);

  return txSkeletonObj;
};

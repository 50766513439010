import { Indexer, helpers, utils, config } from "@ckb-lumos/lumos";
import {
  Aggregator,
  append0x,
  buildCancelTx,
  buildMakerTx,
  buildTakerTx,
  calculateNFTMakerListPackage,
  CKBAsset,
  Collector,
  getDexLockScript,
  buildMultiNftsMakerTx,
  OrderArgs,
} from "@nervina-labs/ckb-dex";
import { initConfig, signRawTransaction } from "@joyid/ckb";
import {
  serializeOutPoint,
  serializeScript,
} from "@nervosnetwork/ckb-sdk-utils";
import { BI, formatUnit, parseUnit } from "@ckb-lumos/bi";

import {
  CHAIN_LIST,
  DID_CONTRACT,
  JOYID_CONTRACT,
  PAGE_SIZE,
} from "../utils/const";
import { getSporeScript, predefinedSporeConfigs } from "@spore-sdk/core";
import { RPC } from "@ckb-lumos/lumos";
import { predefined } from "@ckb-lumos/config-manager";

import {
  calculateFeeCompatible, deserializeOutPoints,
  generateSporeCoBuild,
  getTransactionSizeByTx,
} from "../utils/ckbUtils.js";
import { bytes } from "@ckb-lumos/lumos/codec";
import { blockchain } from "@ckb-lumos/base";
import {getDID} from "../utils/global.js";


const MAX_FEE = BI.from("20000000");

const {
  CKB_NODE_RPC_URL,
  CKB_INDEXER_URL,
  DOB_AGGREGATOR_URL,
  JOYID_APP_URL,
  CONFIG,
} = CHAIN_LIST[import.meta.env.VITE_CHAIN];
const isMainnet = import.meta.env.VITE_CHAIN === "mainnet";

initConfig({
  name: "JoyID",
  logo: "https://fav.farm/🆔",
  joyidAppURL: JOYID_APP_URL,
  network: isMainnet ? "mainnet" : "testnet",
});


async function baseRPC(method, req, url = CKB_NODE_RPC_URL) {
  const payload = {
    id: 1,
    jsonrpc: "2.0",
    method,
    params: req ?? null,
  };
  const body = JSON.stringify(payload, null, "");

  const data = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  }).then((res) => res.json());

  if (data.error) {
    throw new Error(`RPC error: ${JSON.stringify(data.error)}`);
  }

  return data.result;
}

export async function getSporesByRPC(
  address,
  limit = PAGE_SIZE,
  after,
  isSpore
) {
  const lockScript = helpers.parseAddress(address, { config: CONFIG });


  const version =
    import.meta.env.VITE_CHAIN === "testnet" ? "preview" : "latest";
  const clusterConfig =
    import.meta.env.VITE_CHAIN === "testnet"
      ? predefinedSporeConfigs.Testnet
      : predefinedSporeConfigs.Mainnet;

  let sporeType;
  if (isSpore) {
    sporeType = getSporeScript(clusterConfig, "Spore", ["v2", version]);
  } else {
    sporeType = {
      script: {
        codeHash: DID_CONTRACT[import.meta.env.VITE_CHAIN].CODE_HASH,
        hashType: DID_CONTRACT[import.meta.env.VITE_CHAIN].HASH_TYPE,
      },
    };
  }

  const limitHex = append0x(limit.toString(16));

  const paramList = [
    {
      script: {
        code_hash: lockScript.codeHash,
        hash_type: lockScript.hashType,
        args: lockScript.args,
      },
      script_type: "lock",
      script_search_mode: "exact",
      filter: {
        script: {
          code_hash: sporeType.script.codeHash,
          hash_type: sporeType.script.hashType,
          args: "0x",
        },
        script_search_mode: "prefix",
        script_type: "type",
      },
    },
    "desc",
    limitHex,
  ];

  if (after) {
    paramList.push(after);
  }

  const cells = await baseRPC("get_cells", paramList);

  return cells;
}

export async function getmarket(limit = PAGE_SIZE, page = 0) {
  const dexLock = getDexLockScript(isMainnet);


  const sporeConfig = isMainnet? predefinedSporeConfigs.Mainnet:predefinedSporeConfigs.Testnet;
  const version = isMainnet?"latest":"preview";

  const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
  const {script:{codeHash,hashType}} = sporeTypeScript;


  let cells = [];

  let indexer = new Indexer(CKB_INDEXER_URL, CKB_NODE_RPC_URL);
  let collector = indexer.collector({
    lock: {
      script: {
        codeHash: dexLock.codeHash,
        hashType: dexLock.hashType,
        args: "0x",
      },
      searchMode: "prefix",
    },
    type: {
      script: {
        codeHash: codeHash,
        hashType: hashType,
        args: "0x",
      },
      searchMode: "prefix",
    },
  });

  for await (const cell of collector.collect()) {
    cells.push(cell);
  }

  cells.sort((a, b) => {
    const a_orderArgs = OrderArgs.fromHex(a.cellOutput.lock.args);
    const b_orderArgs = OrderArgs.fromHex(b.cellOutput.lock.args);

    if (a_orderArgs.totalValue > b_orderArgs.totalValue) {
      return 1;
    } else if (a_orderArgs.totalValue < b_orderArgs.totalValue) {
      return -1;
    } else {
      return 0;
    }
  });

  let objects = [];
  if (page > 0) {
    if (limit * page < cells.length) {
      const subArray = cells.slice(limit * page, limit * (page + 1));
      objects.push(...subArray);
    }
  } else {
    const subArray = cells.slice(0, limit);
    objects.push(...subArray);
  }

  return { objects: cells };
}

export const updateWitness = (rawTx, firstIndex) => {
  if (firstIndex !== -1) {
    while (firstIndex >= rawTx.witnesses.length) {
      rawTx.witnesses.push("0x");
    }
    let witness = rawTx.witnesses[firstIndex];
    const newWitnessArgs = {
      /* 65-byte zeros in hex */
      lock: "0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
    };
    if (witness !== "0x") {
      const witnessArgs = blockchain.WitnessArgs.unpack(bytes.bytify(witness));
      const lock = witnessArgs.lock;
      if (
        !!lock &&
        !!newWitnessArgs.lock &&
        !bytes.equal(lock, newWitnessArgs.lock)
      ) {
        throw new Error(
          "Lock field in first witness is set aside for signature!"
        );
      }
      const inputType = witnessArgs.inputType;
      if (inputType) {
        newWitnessArgs.inputType = inputType;
      }
      const outputType = witnessArgs.outputType;
      if (outputType) {
        newWitnessArgs.outputType = outputType;
      }
    }
    witness = bytes.hexify(blockchain.WitnessArgs.pack(newWitnessArgs));
    rawTx.witnesses[firstIndex] = witness;
  }
};

export async function handleBuildTakerTxWithReiWallet(
  account,
  selectArr,
  addRecord,
  addQueueRecord,
  hashList

) {
  const collector = new Collector({
    ckbNodeUrl: CKB_NODE_RPC_URL,
    ckbIndexerUrl: CKB_INDEXER_URL,
  });

  collector.pushToQueue(hashList)

  const buyer = account;


  const orderOutPoints = [];
  for (let i = 0; i < selectArr.length; i++) {
    const element = selectArr[i];

    const { out_point } = element;


    const txHash = out_point.substring(0, out_point.length - 8);
    const index = out_point.substring(out_point.length - 8);
    const indexFormat = parseInt(`0x${index}`).toString(16);

    orderOutPoints.push({
      txHash: txHash,
      index: `0x${indexFormat}`,
    });
  }

  let beforeQueue = collector.getQueue()
  let beforeLen = beforeQueue.length;

  const { rawTx, witnessIndex } = await buildTakerTx({
    collector,
    buyer,
    orderOutPoints: orderOutPoints.map(serializeOutPoint),
    ckbAsset: CKBAsset.SPORE,
    excludePoolTx: true
  });

  updateWitness(rawTx, witnessIndex);
  const reiwalletDepCell = isMainnet
    ? predefined.LINA.SCRIPTS.SECP256K1_BLAKE160
    : predefined.AGGRON4.SCRIPTS.SECP256K1_BLAKE160;
  rawTx.cellDeps.push({
    outPoint: {
      txHash: reiwalletDepCell.TX_HASH,
      index: reiwalletDepCell.INDEX,
    },
    depType: reiwalletDepCell.DEP_TYPE,
  });


  const rpc = new RPC(CKB_NODE_RPC_URL);
  const fetcher = async (outPoint) => {
    let rs = await rpc.getLiveCell(outPoint, true);
    let cell = {
      cellOutput: {
        capacity: rs.cell?.output.capacity,
        lock: rs.cell?.output.lock,
        type: rs.cell?.output.type,
      },
      data: rs.cell?.data,
      outPoint,
    };
    return cell;
  };
  let txSkeleton = await helpers.createTransactionSkeleton(rawTx, fetcher);

  let txSkeletonObj = helpers.transactionSkeletonToObject(txSkeleton);


 let tx = await window.rei?.ckb.request({
    method: "ckb_sendRawTransaction", data: {
      txSkeleton: txSkeletonObj
    }
  })

  let outputArr = selectArr.map((item)=>item.out_point);

 const outputArrFomat = deserializeOutPoints(outputArr)


  for (let i = 0; i < outputArrFomat.length; i++) {
    let item = outputArrFomat[i];
    let outpoint = {
      tx_hash: item.txHash,
      index: item.index,
    };
    addRecord(outpoint);
  }


  let queueArr = collector.getQueue()

  const size = queueArr.length - beforeLen
  if(size > 0 && queueArr.length){
    let newArr = queueArr.slice(size * -1);
    addQueueRecord(newArr);
  }

  return tx
}

export async function handleBuildTakerTx(
  connectData,
  account,
  selectArr,
  addRecord,
  addQueueRecord,
  hashList
) {

  try{
    const collector = new Collector({
      ckbNodeUrl: CKB_NODE_RPC_URL,
      ckbIndexerUrl: CKB_INDEXER_URL,
    });
    collector.pushToQueue(hashList)

    const buyer = account;

    const aggregator = new Aggregator(DOB_AGGREGATOR_URL);

    const joyID = {
      connectData,
      aggregator,
    };

    const orderOutPoints = [];
    for (let i = 0; i < selectArr.length; i++) {
      const element = selectArr[i];

      const { out_point } = element;


      const txHash = out_point.substring(0, out_point.length - 8);
      const index = out_point.substring(out_point.length - 8);
      const indexFormat = parseInt(`0x${index}`).toString(16);

      orderOutPoints.push({
        txHash: txHash,
        index: `0x${indexFormat}`,
      });
    }


    let beforeQueue = collector.getQueue()
    let beforeLen = beforeQueue.length;

    const { rawTx, witnessIndex } = await buildTakerTx({
      collector,
      joyID,
      buyer,
      orderOutPoints: orderOutPoints.map(serializeOutPoint),
      ckbAsset: CKBAsset.SPORE,
    });


    const signedTx = await signRawTransaction(rawTx, buyer, {
      config: CONFIG,
      witnessIndex,
    });


    let rt = await collector.getCkb().rpc.sendTransaction(signedTx, "passthrough");

    for (let i = 0; i < selectArr.length; i++) {
      let item = selectArr[i];
      addRecord(item.out_point);
    }


    let queueArr = collector.getQueue()
    const size = queueArr.length - beforeLen
    if(size > 0 && queueArr.length){
      let newArr = queueArr.slice(size * -1);
      addQueueRecord(newArr);
    }

    return rt;
  }catch(e){
    console.error(e);
    throw e;
  }




}

export const handleList = async (connectData, account, price, selectItem) => {
  const collector = new Collector({
    ckbNodeUrl: CKB_NODE_RPC_URL,
    ckbIndexerUrl: CKB_INDEXER_URL,
  });

  const seller = account;

  const aggregator = new Aggregator(DOB_AGGREGATOR_URL);

  const joyID = {
    connectData,
    aggregator,
  };

  const listPackage = calculateNFTMakerListPackage(seller);

  const totalValue = parseUnit(price, "ckb").add(listPackage);


  const sporeConfig = isMainnet? predefinedSporeConfigs.Mainnet:predefinedSporeConfigs.Testnet;
  const version = isMainnet?"latest":"preview";

  const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);


  const sporeType = {
    ...sporeTypeScript.script,
    args: selectItem.output.type.args,
  };
  const { rawTx } = await buildMakerTx({
    collector,
    joyID,
    seller,
    // The price whose unit is shannon for CKB native token
    totalValue,
    assetType: append0x(serializeScript(sporeType)),
    ckbAsset: CKBAsset.SPORE,
    excludePoolTx: false
  });

  // You can call the `signRawTransaction` method to sign the raw tx with JoyID wallet through @joyid/ckb SDK
  // please make sure the buyer address is the JoyID wallet ckb address
  const signedTx = await signRawTransaction(rawTx, seller);

  return collector.getCkb().rpc.sendTransaction(signedTx, "passthrough");
};

export const handleMultiList = async (
  connectData,
  account,
  selectItemList,
  addRecord,
  isREI,
  addQueueRecord,
  hashList,
  isDid
) => {
  const collector = new Collector({
    ckbNodeUrl: CKB_NODE_RPC_URL,
    ckbIndexerUrl: CKB_INDEXER_URL,
  });

  collector.pushToQueue(hashList)
  const seller = account;


  const listPackage = calculateNFTMakerListPackage(seller);

  let nfts = [];


  const sporeConfig = isMainnet? predefinedSporeConfigs.Mainnet:predefinedSporeConfigs.Testnet;
  const version = isMainnet?"latest":"preview";

  let sporeTypeScript;

    let didDep
  if(!isDid){
    sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
  }else{
    sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2","did"]);
    let txHash = await getDID()

    const {outPoint:{index},depType} = sporeTypeScript.cellDep;
    didDep =  {
      "outPoint": {
        "txHash": txHash,
        "index": index
      },
      "depType": depType
    }
  }

  for (let i = 0; i < selectItemList.length; i++) {
    const selectItem = selectItemList[i];
    // const totalValue = parseUnit(selectItem.price.toString(), "ckb").add(
    //   listPackage
    // );
    const totalValue = BI.from(selectItem.price.toString()).add(
      listPackage
    );


    const sporeType = {
      ...sporeTypeScript.script,
      args: selectItem.output.type.args,
    };

    nfts.push({ totalValue, assetType: append0x(serializeScript(sporeType)) });
  }

  let buildResult;
  let signedTx;
  let rt;

  let beforeQueue = collector.getQueue()
  let beforeLen = beforeQueue.length;
  if(isREI){
    buildResult = await buildMultiNftsMakerTx(
        {
          collector,
          seller,
          ckbAsset: CKBAsset.SPORE,
          excludePoolTx:true
        },
        nfts
    );


    if(isDid){
      buildResult.rawTx.cellDeps.push(didDep)
    }

    updateWitness(buildResult.rawTx, buildResult.witnessIndex);


    const reiwalletDepCell = isMainnet
        ? predefined.LINA.SCRIPTS.SECP256K1_BLAKE160
        : predefined.AGGRON4.SCRIPTS.SECP256K1_BLAKE160;
    buildResult.rawTx.cellDeps.push({
      outPoint: {
        txHash: reiwalletDepCell.TX_HASH,
        index: reiwalletDepCell.INDEX,
      },
      depType: reiwalletDepCell.DEP_TYPE,
    });

    signedTx = await window.rei?.ckb.request({method:"ckb_signTransaction",data:{
        txSkeleton:buildResult.rawTx
      }})

  }else{

    const aggregator = new Aggregator(DOB_AGGREGATOR_URL);

    const joyID = {
      connectData,
      aggregator,
    };
    buildResult = await buildMultiNftsMakerTx(
        {
          collector,
          joyID,
          seller,
          ckbAsset: CKBAsset.SPORE,
          excludePoolTx:true
        },
        nfts
    );
    if(isDid){
      buildResult.rawTx.cellDeps.push(didDep)
    }
    signedTx = await signRawTransaction(buildResult.rawTx, seller);


  }

  rt = collector.getCkb().rpc.sendTransaction(signedTx, "passthrough");

  for (let i = 0; i < selectItemList.length; i++) {
    let item = selectItemList[i];
    let outpoint = {
      tx_hash: item.out_point.tx_hash,
      index: item.out_point.index,
    };
    addRecord(outpoint);
  }


  let queueArr = collector.getQueue()
  const size = queueArr.length - beforeLen
  if(size > 0 && queueArr.length){
    let newArr = queueArr.slice(size * -1);
    addQueueRecord(newArr);
  }

  return rt;
};

export async function getMySporeOrder(address, limit = PAGE_SIZE, after,isDid) {
  const ownerlock = helpers.parseAddress(address, { config: CONFIG });
  const limitHex = append0x(limit.toString(16));
  const dexLock = getDexLockScript(isMainnet);


  const sporeConfig = isMainnet? predefinedSporeConfigs.Mainnet:predefinedSporeConfigs.Testnet;
  const version = isMainnet?"latest":"preview";

  let sporeTypeScript
  if(!isDid){
    sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
  }else{
    sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2","did"]);
  }




  const paramList = [
    {
      script: {
        code_hash: dexLock.codeHash,
        hash_type: dexLock.hashType,
        args: `${serializeScript(ownerlock)}`,
      },
      script_type: "lock",
      script_search_mode: "prefix",
      filter: {
        script: {
          code_hash: sporeTypeScript.script.codeHash,
          hash_type: sporeTypeScript.script.hashType,
          args: "0x",
        },
        script_search_mode: "prefix",
        script_type: "type",
      },
    },
    "asc",
    limitHex,
  ];
  if (after) {
    paramList.push(after);
  }
  const cells = await baseRPC("get_cells", paramList);

  return cells;
}

export async function handleCancelOrder(
  connectData,
  account,
  selectItem,
  addRecord,
  isREI,
  addQueueRecord,
  hashList,
  isDid
) {


  const seller = account;

  const aggregator = new Aggregator(DOB_AGGREGATOR_URL);

  const joyID = {
    connectData,
    aggregator,
  };


  const collector = new Collector({
    ckbNodeUrl: CKB_NODE_RPC_URL,
    ckbIndexerUrl: CKB_INDEXER_URL,
  });


  collector.pushToQueue(hashList)

  // const dexLock = getDexLockScript(isMainnet);
  // // const sporeType = getSporeTypeScript(isMainnet);
  // const ownerlock = helpers.parseAddress(seller, { config: CONFIG });

  const orderOutPoints = [];
  for (let i = 0; i < selectItem.length; i++) {
    const element = selectItem[i];

    orderOutPoints.push({
      txHash: element.out_point.tx_hash,
      index: element.out_point.index,
    });
  }

  if (orderOutPoints.length <= 0) {
    throw new Error("not find order");
  }

  let sporeTypeScript;
  const sporeConfig = isMainnet? predefinedSporeConfigs.Mainnet:predefinedSporeConfigs.Testnet;
  const version = isMainnet?"latest":"preview";

  let didDep
  if(isDid){
    sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2","did"]);
    let txHash = await getDID()

    const {outPoint:{index},depType} = sporeTypeScript.cellDep;
    didDep =  {
      "outPoint": {
        "txHash": txHash,
        "index": index
      },
      "depType": depType
    }
  }

  let buildResult;
  let signedTx;
  let beforeQueue = collector.getQueue()
  let beforeLen = beforeQueue.length;

  if(isREI){
    buildResult = await buildCancelTx({
      collector,
      seller,
      orderOutPoints: orderOutPoints.map(serializeOutPoint),
      ckbAsset: CKBAsset.SPORE,
      excludePoolTx: true
    });

    if(isDid){
      buildResult.rawTx.cellDeps.push(didDep)
    }

    updateWitness(buildResult.rawTx, buildResult.witnessIndex);


    const reiwalletDepCell = isMainnet
        ? predefined.LINA.SCRIPTS.SECP256K1_BLAKE160
        : predefined.AGGRON4.SCRIPTS.SECP256K1_BLAKE160;

    buildResult.rawTx.cellDeps.push({
      outPoint: {
        txHash: reiwalletDepCell.TX_HASH,
        index: reiwalletDepCell.INDEX,
      },
      depType: reiwalletDepCell.DEP_TYPE,
    });

    signedTx = await window.rei?.ckb.request({method:"ckb_signTransaction",data:{
        txSkeleton:buildResult.rawTx
      }})

  }else{
    buildResult = await buildCancelTx({
      collector,
      joyID,
      seller,
      orderOutPoints: orderOutPoints.map(serializeOutPoint),
      ckbAsset: CKBAsset.SPORE,
      excludePoolTx: true
    });
    if(isDid){
      buildResult.rawTx.cellDeps.push(didDep)
    }

    signedTx = await signRawTransaction(buildResult.rawTx, seller, {
      config: CONFIG,
      witnessIndex:buildResult.witnessIndex,
    });
  }

  let rt = collector.getCkb().rpc.sendTransaction(signedTx, "passthrough");


  for (let i = 0; i < selectItem.length; i++) {
    let item = selectItem[i];
    let outpoint = {
      tx_hash: item.out_point.tx_hash,
      index: item.out_point.index,
    };
    addRecord(outpoint);
  }

  let queueArr = collector.getQueue()
  const size = queueArr.length - beforeLen
  if(size > 0 && queueArr.length){
    let newArr = queueArr.slice(size * -1);
    addQueueRecord(newArr);
  }

  return rt;
}

export const getFeeRate = async () => {
  const rpc = new RPC(CKB_NODE_RPC_URL);

  let result = await rpc.getFeeRateStatistics()

  let maxNum = Math.max(parseInt(result.median),2000).toString(16);
  result.median = `0x${maxNum}`;
  return result;
};

export const buy_rei = async (fee, sporeList, address) => {
  const net = import.meta.env.VITE_CHAIN;
  const newConfig = net === "testnet" ? predefined.AGGRON4 : predefined.LINA;
  const indexer = new Indexer(CKB_INDEXER_URL, CKB_NODE_RPC_URL);
  let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

  const sporeConfig =
    net === "testnet"
      ? predefinedSporeConfigs.Testnet
      : predefinedSporeConfigs.Mainnet;

  let myScript = helpers.addressToScript(address, { config: newConfig });

  let Sum = BI.from(0);
  sporeList.map((item) => {
    Sum = Sum.add(item.price);
  });
  let priceTotal = formatUnit(Sum, "shannon");

  let diffSUM = BI.from(0);

  let sporeCellArr = [];

  for (let i = 0; i < sporeList.length; i++) {
    const rpc = new RPC(CKB_NODE_RPC_URL, newConfig);
    const txHash = sporeList[i].out_point.substring(
      0,
      sporeList[i].out_point.length - 8
    );
    const index = sporeList[i].out_point.substring(
      sporeList[i].out_point.length - 8
    );
    const indexFormat = parseInt(`0x${index}`).toString(16);

    let rt = await rpc.getLiveCell(
      {
        txHash,
        index: `0x${indexFormat}`,
      },
      true
    );

    let cellFormat = {};

    const content = rt.cell.data.content ?? "0x";
    cellFormat.data = content;
    cellFormat.cellOutput = rt.cell.output;
    cellFormat.outPoint = {
      txHash,
      index: `0x${indexFormat}`,
    };

    sporeCellArr.push({ cell: cellFormat, item: sporeList[i] });
  }

  let outputArr = [];
  sporeCellArr.map((cellItem) => {
    const { cell: spore, item } = cellItem;

    txSkeleton = txSkeleton.update("inputs", (inputs) => inputs.push(spore));

    let inputCapacity = spore.cellOutput.capacity;
    let inputOccupid = helpers.minimalCellCapacityCompatible(spore);
    let inputMargin = BI.from(inputCapacity).sub(inputOccupid);

    const outputObj = JSON.parse(JSON.stringify(spore));
    outputObj.cellOutput.lock = myScript;

    let outputMinimal = BI.from(helpers.minimalCellCapacity(outputObj));

    let outputCapacity = outputMinimal.add(inputMargin);

    let diff;

    diff = BI.from(inputCapacity).sub(outputCapacity);
    diffSUM = diffSUM.add(diff);

    outputObj.cellOutput.capacity = `0x${outputCapacity.toString(16)}`;

    outputArr.push(outputObj);
    txSkeleton = txSkeleton.update("outputs", (outputs) =>
      outputs.push(outputObj)
    );

    const priceNew = BI.from(item.price);
    let tolockScript = helpers.addressToScript(item.ckb_address, {
      config: newConfig,
    });
    const newSellerPrice = priceNew.add(diff);

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
      outputs.push({
        cellOutput: {
          capacity: `0x${newSellerPrice.toString(16)}`,
          lock: tolockScript,
        },
        data: "0x",
      })
    );
  });
  let needCapacity = BI.from(
    helpers.minimalCellCapacity({
      cellOutput: {
        lock: myScript,
        capacity: BI.from(0).toHexString(),
      },
      data: "0x",
    })
  )
    .add(MAX_FEE)
    .add(priceTotal);

  // let utxo = await getUtxo()

  const collect_ckb = indexer.collector({
    lock: {
      script: myScript,
      searchMode: "exact",
    },
    type: "empty",
  });

  const inputs_ckb = [];
  let ckb_sum = BI.from(0);
  for await (const collect of collect_ckb.collect()) {
    inputs_ckb.push(collect);
    ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
    if (ckb_sum.gte(needCapacity)) {
      break;
    }
  }

  if (ckb_sum.lt(needCapacity)) {
    throw new Error("Not Enough capacity found");
  }

  let cellDep_script_lock;

  const { codeHash: myCodeHash, hashType: myHashType } = myScript;

  for (let key in newConfig.SCRIPTS) {
    let item = newConfig.SCRIPTS[key];
    if (item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType) {
      cellDep_script_lock = item;
      break;
    }
    throw new Error("script not found");
  }


  const version = net === "testnet" ? "preview" : "latest";
  const sporeTypeScript = getSporeScript(sporeConfig, "Spore", ["v2", version]);

  txSkeleton = txSkeleton.update("inputs", (inputs) =>
    inputs.push(...inputs_ckb)
  );

  const outputCapcity = ckb_sum.sub(priceTotal).sub(MAX_FEE);

  txSkeleton = txSkeleton.update("outputs", (outputs) =>
    outputs.push({
      cellOutput: {
        capacity: `0x${outputCapcity.toString(16)}`,
        lock: myScript,
      },
      data: "0x",
    })
  );

  const {
    TX_HASH: tx_hash_lock,
    INDEX: index_lock,
    DEP_TYPE: dep_type_lock,
  } = cellDep_script_lock;

  txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
    cellDeps.push({
      outPoint: {
        txHash: tx_hash_lock,
        index: index_lock,
      },
      depType: dep_type_lock,
    })
  );

  const { TX_HASH, INDEX, DEP_TYPE } = JOYID_CONTRACT[net];
  txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
    cellDeps.push({
      outPoint: {
        txHash: TX_HASH,
        index: INDEX,
      },
      depType: DEP_TYPE,
    })
  );

  txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
    cellDeps.push(sporeTypeScript.cellDep)
  );


  let sporeCells = [];
  let outputCells = [];

  sporeCellArr.map((cellItem) => {
    const { cell: spore } = cellItem;
    const {
      cellOutput: {
        capacity: capacity_contract,
        type: {
          args: args_contract_type,
          codeHash: code_hash_contract_type,
          hashType: hash_type_contract_type,
        },
      },
      data: output_contract,
    } = spore;

    let outputCell = {
      cellOutput: {
        capacity: capacity_contract,
        lock: myScript,
        type: {
          codeHash: code_hash_contract_type,
          hashType: hash_type_contract_type,
          args: args_contract_type,
        },
      },
      data: output_contract,
    };

    sporeCells.push(spore);
    outputCells.push(outputCell);
  });

  let sporeCoBuild = generateSporeCoBuild(sporeCells, outputCells);

  const inputArr = txSkeleton.get("inputs").toArray();
  for (let i = 0; i < inputArr.length; i++) {
    txSkeleton = txSkeleton.update("witnesses", (witnesses) =>
      witnesses.push("0x")
    );
  }

  const firstIndex = txSkeleton
    .get("inputs")
    .findIndex((input) =>
      bytes.equal(
        blockchain.Script.pack(input.cellOutput.lock),
        blockchain.Script.pack(myScript)
      )
    );
  if (firstIndex !== -1) {
    while (firstIndex >= txSkeleton.get("witnesses").size) {
      txSkeleton = txSkeleton.update("witnesses", (witnesses) =>
        witnesses.push("0x")
      );
    }
    let witness = txSkeleton.get("witnesses").get(firstIndex);
    const newWitnessArgs = {
      /* 65-byte zeros in hex */
      lock: "0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
    };
    if (witness !== "0x") {
      const witnessArgs = blockchain.WitnessArgs.unpack(bytes.bytify(witness));
      const lock = witnessArgs.lock;
      if (
        !!lock &&
        !!newWitnessArgs.lock &&
        !bytes.equal(lock, newWitnessArgs.lock)
      ) {
        throw new Error(
          "Lock field in first witness is set aside for signature!"
        );
      }
      const inputType = witnessArgs.inputType;
      if (!!inputType) {
        newWitnessArgs.inputType = inputType;
      }
      const outputType = witnessArgs.outputType;
      if (!!outputType) {
        newWitnessArgs.outputType = outputType;
      }
    }
    witness = bytes.hexify(blockchain.WitnessArgs.pack(newWitnessArgs));

    txSkeleton = txSkeleton.update("witnesses", (witnesses) =>
      witnesses.set(firstIndex, witness)
    );
  }

  const {
    cellOutput: {
      type: { codeHash: code_hash_contract_type },
    },
  } = sporeCellArr[0].cell;

  const contractIndex = txSkeleton
    .get("inputs")
    .findIndex(
      (input) => input.cellOutput.type?.codeHash === code_hash_contract_type
    );
  while (contractIndex >= txSkeleton.get("witnesses").size) {
    txSkeleton = txSkeleton.update("witnesses", (witnesses) =>
      witnesses.push("0x")
    );
  }
  txSkeleton = txSkeleton.update("witnesses", (witnesses) =>
    witnesses.set(txSkeleton.get("inputs").size, sporeCoBuild)
  );

  const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton);

  const size = getTransactionSizeByTx(unsignedTx);

  const newFee = calculateFeeCompatible(size, fee);

  const outputCapacityFact = ckb_sum.sub(newFee).sub(priceTotal);

  let outputs = txSkeleton.get("outputs").toArray();
  let item = outputs[outputs.length - 1];

  item.cellOutput.capacity = outputCapacityFact.toHexString();

  txSkeleton = txSkeleton.update("outputs", (outputs) => {
    outputs.set(outputs.size - 1, item);
    return outputs;
  });

  return txSkeleton;
};

import ConnectBtn from "../components/connectBtn.jsx";
import Balance from "../components/balace.jsx";
import {useSelector} from "react-redux";
import Transfer from "../components/joyid/transfer.jsx";
import SporeType from "../components/layout/sporeType.jsx";

export default function TransferOuter() {
    const account = useSelector(store => store.account);

    return <main className="flex flex-1 flex-col h-full bg-gray-100">
        <div className="flex justify-between border-b  border-gray-300  w-full  py-6 px-8 ">
            <div>
                <div
                    className="sm:mt-20 flex text-black text-hd1mb font-Montserrat font-bold">
                    Transfer My DOBs
                </div>
                {
                    !!window.rei?.ckb &&!!account && <Balance />
                }
            </div>

            <ConnectBtn/>
        </div>

        <div className="flex flex-1 min-h-0">
            <SporeType />
            <Transfer/>
        </div>
    </main>
}

import {useEffect, useState} from 'react';
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";

import {Chrome, Wallet} from "lucide-react"
import {useSelector} from "react-redux";
import useIDB from "../useHook/useIndexDB.jsx";

import store from "../store/index.js";
import {saveAccount, saveConnectData, saveHash, saveJoyidInfo} from "../store/reducer.js";
import ConnectModal from "./connectModal.jsx";
import {shortAddress} from "../utils/global.js";
import ReiImg from "../assets/rei.png";
import useQueue from "../useHook/useQueue.jsx";
import {Unplug} from "lucide-react";

const Button = styled.div`
    background: linear-gradient( to right, #f2882e 0%, #f16135 100%);
    color: #fff;
    padding: 10px  20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`

const RhtBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 10px;
    border: 1px solid #f2882e;
    padding: 5px 20px;
    border-radius: 5px;
    color: #f2882e;
    cursor: pointer;
    .wallet{
        display: none;
    }
    img{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #ccc;
    }
    &:hover{
        .wallet{
            display: inline-block;
        }
    }
`

const ConnectBtn = ({children}) => {
    const [current, setCurrent] = useState('home');
    const navigate = useNavigate();
    const location = useLocation();
    const[show,setShow] = useState(false);
    const account = useSelector(store => store.account);

    const {deleteExpired} = useIDB();
    const {deleteQueueExpired} = useQueue();



    useEffect(() => {
        const cur = location.pathname.split("/")[1]
        setCurrent(cur)
        deleteExpired()
        deleteQueueExpired()
    }, []);
    //
    //
    // const onClick = (e) => {
    //     setCurrent(e.key);
    //     navigate(`/${e.key}`)
    // };
    //

    const ConnectRei = async () => {
        try {
            let addr = await window.rei?.ckb?.request({ method: "ckb_requestAccounts" })
            store.dispatch(saveAccount(addr));
            store.dispatch(saveJoyidInfo(null));
            store.dispatch(saveConnectData(null));
        } catch (error) {
            console.error(error);
        }
    }


    // const handleClose = () =>{
    //     setShow(false);
    // }
    //
    // const connectWallet =() =>{
    //     setShow(true);
    // }

    const Disconnect = () => {
        store.dispatch(saveAccount(null));
        store.dispatch(saveConnectData(null));
        store.dispatch(saveJoyidInfo(null));
    }

    return (
        <div className="flex items-center justify-end"
                >
                    <div className="demo-logo" />

                    {
                        !!window.rei?.ckb && !account &&
                        <>
                            <Button onClick={() => ConnectRei()}> <Wallet size={20} />Connect Wallet</Button>
                        </>
                    }
                    {
                        !window.rei?.ckb && <a href="https://chromewebstore.google.com/detail/rei-wallet/jacbgghlojlggfgljfhhlcddicacmbek" target="_blank" rel="noreferrer">
                            <Button> <Chrome />Install Rei Wallet</Button>
                        </a>
                    }


                    {
                        !!window.rei?.ckb &&!!account && <RhtBox onClick={() => Disconnect()} ><img src={ReiImg} alt=""/>{shortAddress(account)}<Unplug className="text-primary011 wallet"  size={18 } /></RhtBox>
                    }

                    {/*{*/}
                    {/*    !!window?.ckb &&!!account && <Balance />*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    show &&    <ConnectModal show={show} handleClose={handleClose} />*/}
                    {/*}*/}

                </div>


    );
};
export default ConnectBtn;

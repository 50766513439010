const dexRpc = import.meta.env.VITE_DEX_API;

export const getClusterList = async () => {
  const res = await fetch(dexRpc + "/api/getclusterlist?pageSize=9999&page=1");
  const data = await res.json();
  return data;
};

export const sendTx = async (tx) => {
  const res = await fetch(dexRpc + "/api/sendtx", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ rawTx: tx }),
  });
  const data = await res.json();
  if (data.message) {
    let message = JSON.parse(data.message);
    throw new Error(message.message);
  }
  return data;
};

export const getListed = async (clusterId, page, pageSize) => {
  const res = await fetch(
    dexRpc +
      "/api/listed/" +
      clusterId +
      "?page=" +
      page +
      "&pageSize=" +
      pageSize +
      "&orderList=price_ASC"
  );
  const data = await res.json();
  return data;
};
export const getAllListed = async (page, pageSize) => {
  const res = await fetch(
    dexRpc +
      "/api/getalllisted/" +
      "?page=" +
      page +
      "&pageSize=" +
      pageSize +
      "&orderList=price_ASC"
  );
  const data = await res.json();
  return data;
};

export const getMyDobs = async (addr, page, pageSize, orderList) => {
  const res = await fetch(
      dexRpc +
      "/api/myalldobs/" +
      addr +
      "?page=" +
      page +
      "&pageSize=" +
      pageSize +
      "&orderList=id_DESC"
  );
  const data = await res.json();
  return data;
};

export const getMyOrders = async (addr, page, pageSize, searchList) => {
  const res = await fetch(
    dexRpc +
      "/api/mydobswithoutcluster/" +
      addr +
      "?page=" +
      page +
      "&pageSize=" +
      pageSize +
      "&orderList=id_DESC" +
      "&searchList=" +
      searchList
  );
  const data = await res.json();
  return data;
};
